var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "요청상세" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.preStartupCheckItem,
                          mappingType: _vm.mappingType,
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.savePreStartupCheckItem,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  staticClass: "q-pb-sm",
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.preStartupCheckItem.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupCheckItem, "plantCd", $$v)
                    },
                    expression: "preStartupCheckItem.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  staticClass: "q-pb-sm",
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    codeGroupCd: "PSR_CHECK_TYPE_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "psrCheckTypeCd",
                    label: "점검유형",
                  },
                  model: {
                    value: _vm.preStartupCheckItem.psrCheckTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupCheckItem, "psrCheckTypeCd", $$v)
                    },
                    expression: "preStartupCheckItem.psrCheckTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showLevel1,
                      expression: "showLevel1",
                    },
                  ],
                  staticClass: "q-pb-sm",
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "대분류",
                    name: "first",
                  },
                  model: {
                    value: _vm.first,
                    callback: function ($$v) {
                      _vm.first = $$v
                    },
                    expression: "first",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showLevel2,
                      expression: "showLevel2",
                    },
                  ],
                  staticClass: "q-pb-sm",
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "중분류",
                    name: "second",
                  },
                  model: {
                    value: _vm.second,
                    callback: function ($$v) {
                      _vm.second = $$v
                    },
                    expression: "second",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    label: "항목명",
                    name: "sopPrestartupCheckItemName",
                  },
                  model: {
                    value: _vm.preStartupCheckItem.sopPrestartupCheckItemName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.preStartupCheckItem,
                        "sopPrestartupCheckItemName",
                        $$v
                      )
                    },
                    expression:
                      "preStartupCheckItem.sopPrestartupCheckItemName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-radio", {
                  attrs: {
                    editable: _vm.editable,
                    comboItems: _vm.useFlagItems,
                    label: "LBLUSEFLAG",
                    name: "useFlag",
                  },
                  model: {
                    value: _vm.preStartupCheckItem.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupCheckItem, "useFlag", $$v)
                    },
                    expression: "preStartupCheckItem.useFlag",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    label: "LBLSORTORDER",
                    name: "sortOrder",
                  },
                  model: {
                    value: _vm.preStartupCheckItem.sortOrder,
                    callback: function ($$v) {
                      _vm.$set(_vm.preStartupCheckItem, "sortOrder", $$v)
                    },
                    expression: "preStartupCheckItem.sortOrder",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }